<template>
  <span>
    <v-btn small depressed color="#1B2845" dark @click="fundDialog = true">Fund Wallet</v-btn>
    <v-dialog max-width="450" v-model="fundDialog">
      <v-card flat class="pa-10">
        <v-toolbar-title class="text-center primary--text ">How would you like to fund?</v-toolbar-title>
        <v-btn
          class="mt-10"
          block
          x-large
          color="primary"
          @click="$toast.info('Check back later!')"
        >
          <img style="height: 40px;" :src="require('@/assets/icons/credit-card.png')" alt=""> 
          Card
        </v-btn>

        <v-btn
          class="mt-10"
          block
          x-large
          color="primary"
          @click="banktransfer()"
        >
          <img :src="require('@/assets/icons/payment.png')" style="height: 40px;" alt="" srcset=""> 
          Bank Transfer
        </v-btn>

        <v-btn
          class="mt-10"
          color="primary"
          x-large
          block
          @click="$toast.success('coming soon!')"
        ><img :src="require('@/assets/icons/pos-terminal.png')" style="height: 40px;" alt="" srcset=""> POS</v-btn>

        <v-card-subtitle class="text-center mt-10 mb-1" @click="$toast.success('coming soon!')">Click here to use <b>USSD</b> code</v-card-subtitle>

      </v-card>
    </v-dialog>

    <v-dialog
      max-width="300"
      v-model="cardDialog"
    >
      <v-card
        flat
        class="pa-5"
      >
        <v-form lazy-validation v-model="valid" ref="card">
          <v-toolbar-title class="text-center">Fund Wallet</v-toolbar-title>
          <v-divider></v-divider>
          <v-text-field
            class="mt-5"
            outlined
            label="Amount"
            v-model="amount"
            :rules="[(v) => !!v || 'Please enter amount first!']"
            @keydown="validNumber($event)"
          ></v-text-field>
          <v-btn
            color="primary"
            block
            large
            @click="$refs.card.validate() ? clickGateway() : null"
          >Continue</v-btn>
          <v-btn @click="cardDialog = false" text block class="mt-4">Close</v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="450"
      v-model="bankDialog"
    >
      <v-card
        class="pa-5"
        flat
      >
        <v-toolbar-title class="text-center">Fund Wallet</v-toolbar-title>
        <v-divider></v-divider>
        <v-card-subtitle class="text-center">Transfer or deposit into your
          account Transave wallet using the VFD account
          number geerated for you
        </v-card-subtitle>
        <v-card-subtitle class="text-center"><b>VFD: {{ (user && user.account) ? user.accounts[0].account_number : '' }}</b></v-card-subtitle>
        <v-btn
          block
          large
          color="primary"
          @click="copyText( (user && user.account) ? user.accounts[0].account_number : '' )"
        >Copy VFD Number</v-btn>

        <v-card-subtitle class="text-center mt-10">
          Receive money using your Transave Wallet ID
          from another Transave User
        </v-card-subtitle>
        <v-card-subtitle class="text-center"><b>Trasave ID: {{ (user && user.account) ? user.accounts[1].account_number : '' }}</b></v-card-subtitle>
        <v-btn
          block
          large
          color="primary"
          @click="copyText( (user && user.account) ? user.accounts[1].account_number : '')"
        >Copy Transave ID</v-btn>

        <v-btn text @click="bankDialog = false" block class="mt-4">Close</v-btn>
      </v-card>
    </v-dialog>
    <!-- <v-dialog width="450" v-model="dialog">
      <v-card class="pa-12">
        <img src="../static/assets/logo.png" width="100px" alt="" />

        <div class="form-colored-bg">
          <div class="text-center py-8">
            <small>How Will You Like to Fund?</small>
          </div>
          <v-form lazy-validation v-model="valid" ref="fund">
            <p class="label">Please Select Funding method</p>
            <v-select
              v-model="gateway"
              placeholder="select"
              :items="['Paystack']"
              required
              :rules="[(v) => !!v || 'You need to select an option']"
              outlined
            >
            </v-select>
            <p class="label">Amount</p>

            <v-text-field
              v-model="amount"
              outlined
              required
              :rules="[(v) => !!v || 'Please enter an amount']"
            ></v-text-field>
            <p class="label" v-if="!user.email">Enter your email</p>
            <v-text-field
              v-if="!user.email"
              v-model="email"
              outlined
              required
              :rules="[(v) => !!v || 'Your are required to provide your email']"
            >
            </v-text-field>
            <v-btn
              block
              text
              :loading="loading"
              x-large
              class="primary"
              @click="$refs.fund.validate() ? clickGateway() : null"
              >Debit Card (i% free)</v-btn
            >
            <v-btn text @click="dialog = false" block class="mt-4">Back</v-btn>
          </v-form>
        </div>
      </v-card>
    </v-dialog> -->
    <paystack
      :amount="parseInt(amount) * 100"
      :email="user.email || email"
      :paystackkey="paystackkey"
      :reference="reference"
      :callback="callback"
      :close="close"
      :embed="false"
      id="paystack"
      style="visibility: hidden"
    >
      <i class="fas fa-money-bill-alt"></i> Make Payment
    </paystack>
    <!-- <vue-monnify
      ref="vueMonnifyButton"
      id="monnify"
      style="visibility: hidden"
      :amount="amount"
      :api-key="monnifyApiKey"
      :contract-code="monnifyContractCode"
      :customer-email="user.email || email"
      :is-test-mode="false"
      :on-close="monnifyClose"
      :on-complete="monnifyComplete"
      :reference="reference"
    >
      Make Payment
    </vue-monnify> -->
  </span>
</template>
<script>
import { mapGetters } from 'vuex'
import paystack from 'vue-paystack'
import VueMonnify from 'vue-monnify'
export default {
  components: {
    paystack,
    VueMonnify,
  },
  data() {
    return {
      fundDialog: false,
      dialog: false,
      loading: false,
      valid: true,
      amount: '',
      gateway: '',
      email: '',
      paystackkey: process.env.VUE_APP_ENV_PAYSTACK_PUBLIC_KEY,
      monnifyApiKey: process.env.VUE_APP_ENV_MONNIFY_API_KEY,
      monnifyContractCode: process.env.VUE_APP_ENV_MONNIFY_CONTRACT_CODE,
      reference: '',
      wallet: '',
      cardDialog: false,
      posDialog: false,
      bankDialog: false,
    }
  },
  methods: {
    async getWalletBalance () {
        const data = {
          user_id: this.user.id
        }
        await this.$store.dispatch('auth/wallet', data, { root: true }).then(response => {
          if (response == 0) {
            this.wallet = '0'
          } else {
            this.wallet = response
          }
        })
      },

    createReference() {
      let text = ''
      let possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))

      this.reference = `TS_WEB_${text}`
    },

    clickGateway() {
      this.cardDialog = false
      document.getElementById('paystack').click()
    },

    callback: function (response) {
      this.fundWallet()
      this.getWalletBalance()
    },

    close: function () {
      this.$toast.error('Transaction cancelled!')
    },

    async fundWallet() {
      this.loading = true
      const data = {
        reference: this.reference,
        amount: this.amount,
        user_id: this.user.id,
      }
      await this.$store
        .dispatch('auth/fundwallet', data, { root: true })
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = false
          this.dialog = false
          this.cardDialog = false
          this.posDialog = false
          this.bankDialog = false
          this.$forceUpdate()
          this.$emit('walletFunded')
          this.createReference()
          this.getWalletBalance()
        })
        .catch((error) => {
          this.loading = false
          this.dialog = false
          this.cardDialog = false
          this.posDialog = false
          this.bankDialog = false
          //console.log(error)
        })
    },

    card() {
      this.fundDialog = false
      this.cardDialog = true
    },

    pos() {
      this.fundDialog = false
      this.posDialog = true
    },

    banktransfer() {
      this.fundDialog = false
      this.bankDialog = true
    },

    async copyText(text) {
      await navigator.clipboard.writeText(text);
      this.$toast.success('copied to clipboard!')
    },

    validNumber(e) {
			e.target.value = e.target.value.replace(/[^0-9]+/g, '');
		},
  },

  mounted() {
    this.createReference()
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },
}
</script>
