<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Transave Transaction Receipt"
      :pdf-quaility="2"
      :manual-pagination="false"
      pdf-content-width="100%"
      @hasStartedGeneration="hasGenerated($event)"
      :html-to-pdf-options="{
        filename: 'Transave Transaction Receipt',
        html2canvas: {
            scrollX: 0,
            scrollY: 0,
            scale: 5,
        },
        jsPDF: {
          unit: 'in',
          format: 'a6',
          orientation: 'portrait'
        },
      }"
      ref="transactionReceipt"
    >
      <section slot="pdf-content">
        <v-card
          class="pa-2 pr-2 pb-5"
        >
          <v-card-title style="justify-content: center;">
            <img :src="require('../../../public/favicon.png')" height="50" alt=""> 
            <span style="margin-left: -5px; color: #C1A13D;">Tran</span>
            <span style="color: #171B70;">Save</span>
          </v-card-title>
          <v-toolbar style="display: flex; justify-content: center;" flat>TRANSACTION DETAILS</v-toolbar>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Receiver Account Name:</v-list-item-title>
                  <div class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.receiver_name }}</div>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Destination Account Number:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.receiver_account_number }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Destination Bank:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.bank_name }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Source Account Name:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.sender_name }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Source Account Number:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.sender_account_number }}</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <div style="float: left; font-size: 12px;">Status:</div>
                  <span class="text-break primary--text" style="float: right; font-size: 14px; color: #171B70;">Successful</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Tran. Date:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.created_at | tx_date }}</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Amount:</v-list-item-title>
                  <span class="primary--text" style="float: right; font-size: 14px; color: #171B70;">&#8358;{{ item.amount | formatPrice }}</span>
                </div>
                <v-divider></v-divider>
                <v-card-subtitle style="text-align: center; color: #333;">Generated By Transave</v-card-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </section>
    </vue-html2pdf>
    <v-container class="px-5 mb-10">
      <v-alert v-show="!multiple_wallets_created" color="red" class="my-5" dark
        >Log ticket: Account not found
        <v-btn color="primary" @click="resolveAccount">Resolve</v-btn></v-alert
      >
      <v-row>
        <v-col cols="12" md="8">
          <div class="text-overline font-weight-bold">Wallet Balance</div>
          <div class="primary--text">
            <sup class="text-h6">&#8358;</sup>
            <span v-if="wallet == ''" class="text-h3">********</span
            ><span v-else class="text-h3">{{ wallet | formatPrice }}</span>
          </div>
          <div class="text-caption mt-2">
            Wallet ID:
            <span class="font-weight-bold secondary--text">{{
              account_number ? account_number : ""
            }}</span>
          </div>
          <div class="mt-4">
            <v-btn
              small
              class="mr-4"
              color="#1B2845"
              dark
              depressed
              to="/dashboard/savings"
              >Quick Save</v-btn
            >
            <fund-wallet @walletFunded="getWalletBalance()" />
          </div>
        </v-col>
        <v-col cols="12" md="4" class="mt-5">
          <v-card class="py-2 px-7 primary rounded-lg" elevation="2">
            <v-row>
              <v-col cols="9">
                <div class="text-overline font-weight-bold white--text">
                  Account Status
                </div>
                <div v-if="account_type == null" class="text-body-1 font-weight-medium secondary--text">
                  fetching data...
                </div>
                <div v-else class="text-h5 font-weight-medium secondary--text">
                  {{ account_type }}
                </div>
                <div class="text-overline font-weight-bold white--text">
                  {{ Math.round(percentage) + '%' }} Complete
                </div>
              </v-col>
              <v-col cols="3" class="text-center pt-8">
                <v-progress-circular
                  size="50"
                  width="6"
                  color="secondary"
                  :value="percentage"
                ><small class="white--text font-weight-bold">{{ Math.round(percentage) + '%' }}</small></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--v-divider class="my-10"></v-divider-->
      <v-container
        fluid
        style="background: #e3f2fd"
        class="grey lighten-5 pa-3 pa-md-5 my-10 rounded-lg"
      >
        <v-row>
          <v-col cols="12" md="3">
            <v-card class="py-3 px-5 rounded-lg" elevation="2">
              <v-row>
                <v-col cols="8">
                  <div class="text-subtitle-1 font-weight-bold primary--text">
                    Total Savings
                  </div>
                  <div class="mt-3 text--secondary mb-0 font-weight-bold pt-1 pb-2">
                    <span class="text-h6">&#8358;</span> {{ 0 | formatPrice }}
                  </div>
                </v-col>
                <v-col cols="4" class="text-center">
                  <v-img src="@/assets/icons/save-icon.png"></v-img>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="py-3 px-5 rounded-lg" elevation="2">
              <v-row>
                <v-col cols="8">
                  <div class="text-subtitle-1 font-weight-bold primary--text">
                    Net Loans Granted
                  </div>
                  <div class="mt-3 text--secondary mb-0 font-weight-bold pt-1 pb-2">
                    <span class="text-h6">&#8358;</span>
                    {{ loanBalance ? loanBalance : 0 | formatPrice }}
                  </div>
                </v-col>
                <v-col cols="4" class="text-center">
                  <v-img src="@/assets/icons/loan-svg.png"></v-img>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <router-link :to="{ name: 'Giveaway' }">
              <v-card class="py-5 px-5 rounded-lg" elevation="2">
                <v-row>
                  <v-col cols="8">
                    <div class="text-subtitle-1 pb-3 font-weight-bold primary--text">
                      Giveaway &amp; Donations
                    </div>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-img src="@/assets/icons/donation.png"></v-img>
                  </v-col>
                </v-row>
              </v-card>
            </router-link>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="py-3 px-5 primary rounded-lg" elevation="2">
              <v-row>
                <v-col cols="9">
                  <div class="text-h5 font-weight-medium white--text">
                    Referral 
                  </div>
                </v-col>
                <v-col cols="3" class="text-center">
                  <v-icon color="secondary" size="40">mdi-battlenet</v-icon>
                </v-col>
                <v-col cols="12" class="pa-1 pb-2 white--text text-overline text-center">
                    <small>Get free &#8358;100 per referral</small>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


<!--      <v-card class="mt-15">-->
<!--        <div class="header pa-5 rounded-lg grey lighten-5 elevation-0">-->
<!--          <v-row>-->
<!--            <v-col cols="8" md="10">-->
<!--              <div class="title-m primary&#45;&#45;text text-h5 font-weight-medium">Recent Transactions</div>-->
<!--            </v-col>-->
<!--            <v-col cols="4" md="2">-->
<!--              <v-btn-->
<!--                block-->
<!--                class="ma-0 secondary"-->
<!--                outlined-->
<!--                dark-->
<!--                large-->
<!--                @click="exportSelected()"-->
<!--                :disabled="this.selected.length < 2"-->
<!--                >Export</v-btn-->
<!--              >-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          -->
<!--          <v-row justify="center" align="center">-->
<!--            <v-col class="pb-0">-->
<!--              <v-text-field-->
<!--                v-model="search"-->
<!--                append-icon="mdi-magnify"-->
<!--                placeholder="Search transaction history"-->
<!--                solo outlined-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
<!--            <v-col class="pb-0">-->
<!--              <v-select-->
<!--                placeholder="Filter transaction type"-->
<!--                class=""-->
<!--                v-model="form.type"-->
<!--                :items="[-->
<!--                  { text: 'Credit', value: 'Credit' },-->
<!--                  { text: 'Debit', value: 'Debit' },-->
<!--                ]" solo outlined-->
<!--              ></v-select>-->
<!--            </v-col>-->
<!--            <v-col class="pb-0 hidden-xs-only">-->
<!--              <v-menu-->
<!--                v-model="menu"-->
<!--                :close-on-content-click="false"-->
<!--                max-width="290"-->
<!--              >-->
<!--                <template v-slot:activator="{ on }" flat>-->
<!--                  <v-text-field-->
<!--                    append-icon="event_note"-->
<!--                    outlined solo-->
<!--                    light-->
<!--                    :value="start_date"-->
<!--                    placeholder="Date"-->
<!--                    readonly-->
<!--                    v-on="on"-->
<!--                  ></v-text-field>-->
<!--                </template>-->
<!--                <v-date-picker-->
<!--                  light-->
<!--                  v-model="start_date"-->
<!--                  @change="-->
<!--                    startRange();-->
<!--                    menu = false;-->
<!--                  "-->
<!--                ></v-date-picker>-->
<!--              </v-menu>-->
<!--            </v-col>-->
<!--            <v-col class="pb-0 hidden-xs-only">-->
<!--              <v-menu-->
<!--                v-model="menu1"-->
<!--                :close-on-content-click="false"-->
<!--                max-width="290"-->
<!--              >-->
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-text-field-->
<!--                    append-icon="event_note"-->
<!--                    outlined solo-->
<!--                    :value="end_date"-->
<!--                    placeholder="Date"-->
<!--                    readonly-->
<!--                    v-on="on"-->
<!--                  ></v-text-field>-->
<!--                </template>-->
<!--                <v-date-picker-->
<!--                  light-->
<!--                  v-model="end_date"-->
<!--                  @change="-->
<!--                    endRange();-->
<!--                    menu1 = false;-->
<!--                  "-->
<!--                ></v-date-picker>-->
<!--              </v-menu>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </div>-->

<!--        <v-data-table-->
<!--          class="mt-0"-->
<!--          :headers="headers"-->
<!--          :items="filterTransactions"-->
<!--          :search="search"-->
<!--          show-select-->
<!--          item-key="id"-->
<!--          :items-per-page="5"-->
<!--          :loading="loader"-->
<!--          v-model="selected"-->
<!--        >-->
<!--          &lt;!&ndash; <template slot="no-data"> No Transactions yet </template> &ndash;&gt;-->
<!--          <template v-slot:[`item.amount`]="{ item }">-->
<!--            <div>&#8358; {{ item.amount | formatPrice }}</div>-->
<!--          </template>-->
<!--          <template v-slot:[`item.action`]="{ item }">-->
<!--            <v-btn color="primary" small @click="exportHistory(item)"-->
<!--              >Download</v-btn-->
<!--            >-->
<!--          </template>-->
<!--          &lt;!&ndash; <template v-slot:[`item.number`]="{ index }">-->
<!--          <div>{{ index + 1 }}</div>-->
<!--        </template> &ndash;&gt;-->
<!--          <template v-slot:[`item.created_at`]="{ item }">-->
<!--            <div style="width: 80px">{{ item.created_at | txDate }}</div>-->
<!--          </template>-->
<!--        </v-data-table>-->
<!--      </v-card>-->


      <v-card class="mt-15">
        <div class="header pa-5 rounded-lg grey lighten-5 elevation-0">
          <div class="title-m primary--text text-h5 font-weight-medium">My Referrals</div>
        </div>
        <v-data-table
          :headers="ref_headers"
          :items="referrals"
          :search="ref_search"
          :items-per-page="5"
          :loading="loadRef"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            <div>{{ item.created_at | formatDate }}</div>
          </template>
          <template v-slot:[`item.number`]="{ index }">
            <div>{{ index + 1 }}</div>
          </template>
        </v-data-table>
      </v-card>

      <!--v-card flat class="mt-10">
        <div class="header rounded-lg grey lighten-5 elevation-0">
          <div class="text-h6 pa-8 primary--text">TranSave News</div>
        </div>
        <v-card class="news-card elevation-0"> </v-card>
      </v-card-->
    </v-container>
    <v-footer class="ma-0 grey lighten-5">
      <v-container fluid class="pa-5 text-center">
        <div class="text-h5 font-weight-bold" style="color: #1a237e">
          Download the Mobile app
        </div>
        <div class="mt-5">
          <v-chip class="py-6 pr-4 rounded-lg" dark color="black" label>
            <img
              src="@/assets/brand/apple-logo.svg"
              width="30"
              height="30"
              alt=""
            />
            <div class="ml-2">
              <div class="text-caption">Download on</div>
              <div class="mt-n2 text-subtitle-1 font-weight-bold">
                Apple Store
              </div>
            </div>
          </v-chip>
          <a
            href="https://play.google.com/store/apps/details?id=com.finance.tranSave"
            target="_blank"
          >
            <v-chip class="ma-2 py-6 rounded-lg" dark color="black" label>
              <img
                src="@/assets/brand/google-play.svg"
                width="30"
                height="30"
                alt=""
              />
              <div class="ml-2 text-left">
                <div class="text-caption">Get on</div>
                <div class="mt-n2 text-subtitle-1 font-weight-bold">
                  Google Play
                </div>
              </div>
            </v-chip>
          </a>
        </div>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
import FundWallet from "../../components/FundWallet.vue";
import {mapActions, mapGetters} from "vuex";
export default {
  components: {
    FundWallet,
  },
  layout: "dashboard",
  transition: "default",
  data() {
    return {
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu: false,
      wallet: "",
      multiple_wallets_created: false,
      dialog: false,
      headers: [
        // { text: '#', value: 'number', sortatble: false, orderable: false},
        { text: "Debit", value: "sender_name" },
        { text: "Credit", value: "receiver_name" },
        { text: "Amount", value: "amount" },
        { text: "Bank", value: "bank_name" },
        { text: "Type", value: "type" },
        // { text: 'Narration', value: 'description' },
        { text: "Date", value: "created_at" },
        { text: "Action", value: "action" },
      ],
      ref_headers: [
        { text: "#", value: "number", orderables: false, sortable: false },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Date", value: "created_at" },
      ],
      referrals: [],
      loadRef: false,
      ref_search: "",
      history: [],
      search: "",
      selected: [],
      loader: false,
      startDate: "",
      endDate: "",
      form: {
        type: null,
      },
      item: {},
      percentage: 0,
      account_type: null,
      kyc_level: []
    };
  },
  mounted() {
    this.getWalletBalance();
    // this.getLoanBalance();
    if(this.getKyc()) {
   //   this.kycPercentage()
     // this.accountType()
    }
    this.checkMultipleWallet();
    this.transactionHistory();
    this.getReferrals();
    this.getLoans();
  },
  methods: {
    ...mapActions('loan', ['userLoans']),
    async getWalletBalance() {
      const data = {
        user_id: this.user.id,
      };
      try {
        let response = await this.$store.dispatch("auth/wallet", data, { root: true })
        if (response == 0) {
          this.wallet = "0";
        } else {
          this.wallet = response;
        }
      } catch (error) {
            
      }
    },

    async getKyc() {
      try {
        let response = await this.$store.dispatch("auth/getkyc", this.user.id)
        this.kyc_level  = response.data
        this.kycPercentage()
        this.accountType()
      } catch (error) {

      }
    },

    async kycPercentage() {
      const id = this.kyc_level.id
      try {
        let response = await this.$store.dispatch('auth/kycpercentage', id)
        this.percentage = response.data
      } catch (error) {

      }
    },

    async accountType() {
      const id = this.kyc_level.user.account_type_id
      try {
        let response = await this.$store.dispatch('auth/accounttype', id)
        this.account_type = response.data.name
      } catch (error) {

      }
    },
    
    async resolveAccount() {
      const data = {
        user_id: this.user.id,
      };
      try {
        let response = await this.$store.dispatch("auth/resolvewallet", data, { root: true })
        this.$toast.success(
          "Issue will be resolved now please check back in the next 10mins"
        );
      } catch(err) {
        this.$toast.error(err.response.data.message);
      }
    },

    checkMultipleWallet() {
      if (
        this.user.accounts
          ? this.user.accounts[0].account_number != null &&
            this.user.accounts[1].account_number != null
          : ""
      ) {
        this.multiple_wallets_created = true;
      } else {
        this.user.accounts
          ? (this.user.accounts[0].account_number = null)
          : "";
        this.user.accounts
          ? (this.user.accounts[1].account_number = null)
          : "";
        this.multiple_wallets_created = false;
      }
    },

    startRange() {
      this.startDate = this.start_date;
    },

    endRange() {
      this.endDate = this.end_date;
    },

    async exportHistory(item) {
      this.item = Object.assign({}, item);
      this.$refs.transactionReceipt.generatePdf()
    },

    exportSelected() {
      this.item = this.selected;
    },

    async transactionHistory() {
      this.loader = true;
      let user_id = this.user.id;
      try {
        const response = await this.$store.dispatch(
          "auth/transactionhistory",
          user_id
        );
        this.history = response.transactions;
        this.loader = false;
      } catch (err) {
        
      }
    },

    async getReferrals() {
      this.loadRef = true;
      try {
        let response = await this.$store.dispatch(
          "auth/referrals",
          this.user.id
        );
        this.referrals = response.referrals;
        this.loadRef = false;
      } catch (error) {
        this.loadRef = false
      }
    },
    getLoans() {
      const data = {
        owner_id: this.user.id,
        filter: "",
        search: "",
        end: "",
        start: "",
        page: 1
      };
      this.userLoans(data);
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters('loan', ['loanBalance']),

    filterTransactions() {
      const startDate = this.startDate;
      const endDate = this.endDate;
      const type = this.form.type;
      const latest = this.history.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

      return latest
        .filter((history) => {
          if (startDate === "" && endDate === "" && type == null) return true; // Check if user did not filter transaction
          return history; // return all transactions
        })
        .filter((history) => {
          const formattedDate = this.$options.filters.txDate(history.created_at); // format the date
          if (startDate !== "" && endDate === "" && type == null) {
            // check user only filtered startDate
            return startDate <= formattedDate;
          }
          if (startDate !== "" && endDate !== "" && type == null) {
            // Check if user filtered only startDate and endDate
            return startDate <= formattedDate && formattedDate <= endDate;
          }
          if (startDate === "" && endDate === "" && type !== null) {
            // Check if user only filtered type
            return history.type === type;
          }
          if (startDate !== "" && endDate !== "" && type !== null) {
            // Check if user selected al; filters
            return (
              startDate <= formattedDate &&
              formattedDate <= endDate &&
              type === history.type
            );
          }
          return true;
        });
    },

    account_number () {
      if (this.user.accounts) {
        return this.user.accounts.find(
          x => x.account_name === 'Wallet ID'
        ).account_number
      }
    },
  },
};
</script>
<style lang="scss">
.v-application .elevation-5 {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}
.v-application .elevation-2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06) !important;
}
.v-application .elevation-0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.v-menu__content {
  box-shadow: 0 0.04rem 0.4rem rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #dddddd;
}
.t-color tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
a {
  text-decoration: none;
}
</style>
